import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import SiteMetadata from "../components/site-metadata";
import TextPageLayout from "../components/text-page-layout";

const metadata = {
  title: "Shop Online",
  description: "In questo momento, il shop online non è disponibile. Tuttavia, puoi vedere i prodotti che forniamo nella pagina di Prodotti e fissare un'appuntamento.",
};

const ShopOnlinePage = ({ location }) => (
  <TextPageLayout>
    <SiteMetadata
      location={location}
      {...metadata}
    />

    <h1>{metadata.title}</h1>

    <p>
      In questo momento, il shop online non è disponibile. Tuttavia, puoi vedere
      i prodotti che forniamo nella pagina di
      {" "}
      <Link to="/prodotti">Prodotti</Link>
      {" "}
      e fissare un&apos;appuntamento.
    </p>
  </TextPageLayout>
);

ShopOnlinePage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default ShopOnlinePage;
