/*
 * text-page-layout.js
 * -------------------
 * Exports the text-page-layout component.
 */

import React from "react";
import PropTypes from "prop-types";

import Layout from "./layout";
import Space from "./space";
import Content from "./content";

const TextPageLayout = ({ children }) => (
  <Layout>
    <Space />
    <Content>
      {children}
    </Content>
    <Space size="4rem" />
  </Layout>
);

TextPageLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TextPageLayout;
